.project {
  margin-left: 0 !important;
  height: unset;
  justify-content: unset;
  background: transparent;
  border-bottom: none;
  padding: 0;
  color: var(--secondary-color);
  min-height: 100vh;
  animation: darken 1s ease-in-out forwards 0.8s;
}

.project-img {
  background: no-repeat center / cover;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  scale: 1.1;
  animation: zoomOut 1s ease-in-out forwards;
}

.project-content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
  box-shadow: 0 0 5rem 1rem var(--secondary-color);
  min-height: 100vh;
  backdrop-filter: blur(5px);
  opacity: 0;
  animation: show var(--transition) forwards 1s;
}

.project-content h3 {
  color: var(--primary-color);
}

.project .tag {
  position: absolute;
  top: 2.8rem;
  right: 2rem;
  padding: 0.2rem 0.8rem;
  background-color: var(--fourth-color);
  color: white;
  font-size: 0.8rem;
  border-radius: 10px;
  color: var(--third-color);
}

.project .links {
  display: flex;
}

.project .links .link {
  padding: 0.5rem;
  text-align: center;
}

.project .link h5 {
  transition: color var(--transition) ease-in-out;
}

.project .link i {
  padding: 0;
  color: var(--third-color);
  animation: zoom-in-zoom-out 2s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.project .link:hover i,
.project .link:hover h5 {
  color: var(--primary-color);
  transition: color var(--transition) ease-in-out;
}

.project .fa-2x {
  color: var(--secondary-color);
}

.project .info {
  border-left: 1px solid var(--primary-color);
  padding: 0 1rem;
  margin: 1rem 0;
}

.project span {
  padding: 0 0.4rem 0;
}

.project .info-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media only screen and (min-width: 768px) {
  .project {
    padding: 2rem;
  }

  .project-content {
    border-radius: 15px;
    min-height: unset;
  }
}
