#skills {
  height: unset;
}

.acumen {
  padding-top: 0.6rem;
  display: flex;
  flex-wrap: wrap;
}

.acumen i {
  font-size: 2.5rem;
  transition: color 0.4s;
  padding: 0.4rem;
  text-align: center;
}

.acumen i p {
  font-size: 0.9rem;
  text-align: center;
  padding: 0.3rem;
}
