#education {
  height: auto;
}

/* Media Queries */
@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
  #education {
    min-height: 100vh;
  }
}
