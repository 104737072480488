#experience {
  height: auto;
}

#experience h1 {
  font-size: 4rem;
}

#experience #project-filter {
  display: flex;
  flex-wrap: wrap;
}

#experience #project-filter div {
  padding: 0.5rem 0;
}

#experience #project-filter label {
  padding: 0.3rem 0.8rem;
  margin-right: 0.5rem;
  border-radius: 5px;
  border: 1px solid var(--third-color);
  transition: var(--transition);
}

#experience #project-filter label:hover {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  cursor: pointer;
  transition: var(--transition);
}

#experience #project-filter input {
  display: none;
}

#experience #project-filter input:checked + label {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-weight: 700;
}

.projects {
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 1rem;
  gap: 1rem;
}

.experienceItem {
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  transition: box-shadow var(--transition) ease;
  animation: slideUp var(--transition) ease forwards;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
}

.experienceItem:hover {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  transition: box-shadow var(--transition) ease;
  cursor: pointer;
}

.experienceItem h3 {
  transition: color var(--transition) ease;
}

.experienceItem:hover h3 {
  color: var(--primary-color);
  transition: color var(--transition) ease;
}

.experienceItem:hover .bgImage {
  scale: 1.01;
  transition: all var(--transition);
  opacity: 1;
}

.experienceItem:before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%;
  z-index: 2;
  display: block;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.experienceItem:hover:before {
  -webkit-animation: shine 1.3s;
  animation: shine 1.3s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

.experienceItem .text {
  padding: 0.5rem 1rem;
}

.experienceItem .img-wrap {
  overflow: hidden;
  position: relative;
}

.experienceItem .img-wrap .tag {
  position: absolute;
  bottom: 1rem;
  right: 0;
  padding: 0.2rem 0.8rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: var(--third-color);
  color: white;
  /* font-size: 0.8rem; */
  font-weight: 400;
  z-index: 3;
}

.experienceItem .bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 14rem;
  background: no-repeat center / cover;
  transition: all var(--transition);
  scale: 1;
  opacity: 0.8;
}

/* Media Queries */
@media only screen and (min-width: 600px) {
  .projects {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  .projects {
    grid-template-columns: repeat(3, 1fr);
  }
}
