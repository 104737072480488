#references {
  height: unset;
  overflow-x: hidden;
}

#references > div {
  margin-left: -1rem;
}

.references-container {
  height: 100%;
  /* height: 20rem; */
  /* overflow-y: scroll; */
  padding: 0 1rem;
}

.tab-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-item label {
  display: flex;
  flex-direction: row;
  padding: 0.4rem;
  border-radius: 15px;
  align-items: center;
  width: 15rem;
  transition: box-shadow var(--transition) ease-in-out;
}

.tab-item label:hover {
  transition: box-shadow var(--transition) ease-in-out;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.tab-item label h4 {
  transition: color var(--transition) ease-in-out;
}

.tab-item label:hover h4 {
  color: var(--primary-color);
  transition: color var(--transition) ease-in-out;
}

.tab-item-toggler {
  display: none;
}

.tab-item-toggler:checked .tab-item label {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.tab-item-toggler:checked ~ .recommendations-container {
  display: block;
  animation: slideUp var(--transition) ease;
}

.tab-item-img {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  margin: 0 0.7rem;
  box-shadow: 0 0 0px 3px var(--fourth-color);
  aspect-ratio: 1 / 1;
}

.tab-item-info > * {
  display: block;
  color: var(--third-color);
}

.tab-item-info:hover * {
  color: var(--primary-color);
  transition: color var(--transition);
}

.recommendations-container {
  position: relative;
  opacity: 1;
  transition: opacity var(--transition);
  display: none;
}

.recommendations-container i {
  color: var(--fourth-color);
  font-size: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  margin-left: 0.5rem;
  margin-top: -1.5rem;
}

.recommendations-container p {
  width: 100%;
  margin: 1rem auto 0;
  padding: 1.2rem;
}

.recommendations-container .citation {
  padding: 1rem 0;
  margin: 0;
  text-align: right;
  width: 95%;
}

.recommendations-container .citation a {
  color: var(--third-color);
}

.recommendations-container .citation a:hover {
  color: var(--primary-color);
  transition: color var(--transition);
  cursor: pointer;
}

/* Media Queries */
@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
  #references {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  #references > div {
    display: flex;
  }

  .references-container {
    margin: 0;
    border-right: 1px solid var(--primary-color);
  }

  .tab-item {
    width: 25vw;
  }

  .recommendations-container i {
    margin-left: 0.5rem;
    margin-top: -3rem;
  }

  .recommendations-container p {
    margin: 0;
    padding: 0 1.2rem;
  }

  .tab-item-toggler:checked ~ .recommendations-container {
    animation: slideLeft var(--transition) ease;
  }
}
